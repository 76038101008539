import { Html, useGLTF } from "@react-three/drei"
import { Canvas, extend, ThreeEvent, useFrame, useThree } from "@react-three/fiber"
import CameraControls from "camera-controls"
import React, { Suspense, useContext, useEffect, useRef, useState } from "react"
import * as THREE from 'three'
import { Color } from "three"
import { TouchContext } from "../App"
import { degToRad } from "../utils/carouselLogic"

declare module JSX {
    interface IntrinsicElements {
        "cameraControls": any
    }
}

CameraControls.install({ THREE: THREE })
extend({ CameraControls })

type carousel3D = {
    models: string[],
}
type ItemProps = {
    model: string,
    onClick?: (event: ThreeEvent<MouseEvent>) => void,
    position: {
        x: number,
        y: number,
        angle: number,
    }
}



const Carousel3D = ({ models }: carousel3D) => {
    const [modal, setModal] = useState(false)
    const touch = useContext(TouchContext)
    return (
        <>
            {modal && <div style={{ position: 'fixed', width: '100%', height: '100%', zIndex: '100', color: 'black', left: 0, top: 0, overflow: 'auto', backgroundColor: 'rgba(0,0,0,0.4)', backdropFilter: 'blur(2px)' }}>
                <div style={{ margin: 'auto', padding: '40px', width: '80%', height: '80%', display: 'flex', backgroundColor: 'white', alignItems: 'center', border: '10px solid white' }}>
                    <img src="./face.jpg" width="40%" style={{ marginRight: '40px' }} />
                    The ability to communicate our experiences with others is fundamental to our humanity and grounds us in reality. It is the power of art that can be seen and felt, in which the pulse of life can be communicated in silence that has resonated throughout shared history and has drawn humans, including New Zealand artist Max Brown, to its presence.
                    <br />
                    <br />
                    <br />
                    Max’s works contain motifs of nature, space-time and human emotions in which he merges to communicate his awe of the natural world and explore our placing within it. In recent times, we have witnessed an evolution of our world as we transverse through the age of technological advancement. The melding of the natural and digital have drastically changed our understanding of reality, identity and existence. How we navigate our journey within and between worlds has been the inspiration for Max to delve into digital art media.
                    <br />
                    <br />
                    <br />
                    How we navigate our journey within and between worlds has been the inspiration for Max to delve into digital art media.
                    Utilisation of physical and digital art medium within Max’s latest project The Interstice – a small intervening space, the digital art tokens within the project are direct derivatives of Max’s physical paintings, bounding them to a physical presence. The Interstice is the culmination of Max’s experience between spaces and represents the crossroad of our modern world. This project has been opened to the community to partake in dialogue as Max yields that our placing within these spaces will be determined by the “migration of our communities not the settlement of an individual”.
                </div>
            </div>}
            <div style={{ maxWidth: '100%' }}>

                <Canvas

                    style={{ height: '80vh', width: '100vw', maxWidth: '100%' }}
                    camera={{ position: [0.32, 0, 0], }}
                >
                    <Models models={models} />
                    <ambientLight />
                    {touch && <Html fullscreen>
                        <div style={{ display: 'block', width: '100%', height: '100%', zIndex: '2' }}>

                        </div>
                    </Html>}
                    {/* <Html fullscreen>
                        <button onClick={() => {
                            setModal((prev) => !prev)
                        }}> Hello </button>
                    </Html> */}
                </Canvas>


            </div >
        </>

    )


}

const Models = ({ models }: carousel3D) => {
    const camControlRef = useRef<CameraControls>()
    const { gl, camera, scene, viewport } = useThree();

    useFrame((_, delta) => {
        camControlRef.current!.update(delta)
        camControlRef.current!.rotate(2 * Math.PI * 0.0001, 0, true)

    })
    viewport.aspect = window.innerWidth / window.innerHeight
    useEffect(() => {

        camControlRef.current!.mouseButtons.wheel = CameraControls.ACTION.NONE
        camControlRef.current!.mouseButtons.middle = CameraControls.ACTION.NONE
        camControlRef.current!.mouseButtons.right = CameraControls.ACTION.DOLLY
        camControlRef.current!.mouseButtons.shiftLeft = CameraControls.ACTION.NONE
        camControlRef.current!.touches.two = CameraControls.ACTION.NONE
        camControlRef.current!.touches.three = CameraControls.ACTION.NONE

        camControlRef.current!.azimuthRotateSpeed = 0.25
        camControlRef.current!.minPolarAngle = degToRad(85)
        camControlRef.current!.maxPolarAngle = degToRad(85)
        if (window.innerWidth < 1100) {
            camControlRef.current!.azimuthRotateSpeed = 1
            camControlRef.current!.touches.one = CameraControls.ACTION.NONE
            camControlRef.current?.setPosition(0.0001, 0, 0)
            camControlRef.current!.minPolarAngle = degToRad(75)
            camControlRef.current!.maxPolarAngle = degToRad(105)
            camControlRef.current?.zoom(0.5)
        }

    }, [])
    let radius = 0.27
    const group = useRef<any>()
    scene.background = new Color('black')
    const modelComponents = models.map((model, idx) => {
        let angle = (idx / (models.length) * Math.PI * 2);
        const x = radius * Math.cos(-angle);
        const y = radius * Math.sin(-angle);



        return (
            <Item key={idx} model={model} position={{ x: x, y: y, angle }} />
        )
    })
    return (
        <>
            {/* @ts-ignore */}
            <cameraControls ref={camControlRef} args={[camera, gl.domElement]} />
            <Suspense fallback={null}>
                <group ref={group} >
                    {modelComponents}
                </group >
            </Suspense>

        </>

    )
}

const Item = ({ model, position, onClick }: ItemProps) => {

    const { x, y, angle } = position

    {/* @ts-ignore */ }
    const { nodes, materials } = useGLTF(model)
    return (
        <Suspense fallback={null}>
            <group position={[x, 0.01, y]} rotation={[0, angle + degToRad(-90), 0]} >
                <mesh castShadow receiveShadow geometry={nodes.back.geometry} material={materials.back} />
            </group>
        </Suspense>

    )
}


export default Carousel3D