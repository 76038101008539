import { ThreeEvent } from "@react-three/fiber"
import { HtmlHTMLAttributes, MouseEvent } from "react"

let prevPos: { x: number, y: number }



export const degToRad = (deg: number) => {
    return (Math.PI * 2) * (deg / 360)
}

export const radToDeg = (rad: number) => {
    return 180 / Math.PI * rad
}

// export const onMouseDown = (e: MouseEvent<HTMLDivElement>) => {
//     e.currentTarget?.addEventListener('mousemove', onMouseMove as any)
//     e.currentTarget?.addEventListener('mouseup', onMouseUp as any)
//     e.target?.addEventListener('mouseout', onMouseOut as any)
//     prevPos = { x: e.clientX, y: e.clientY }
//     const { right, left } = e.currentTarget.getBoundingClientRect()
//     // const side = prevPos.x > (right + left) / 2 ? 'right' : 'left'

//     console.log('hi')
//     // console.log(e.currentTarget.offsetWidth);

// }

// export const onMouseMove = (e: MouseEvent<HTMLDivElement>) => {
//     console.log(prevPos.x - e.clientX, prevPos.y = e.clientY)

// }


// export const onMouseUp = (e: MouseEvent<HTMLDivElement>) => {
//     e.currentTarget.removeEventListener('mousemove', onMouseMove as any, false)
//     e.currentTarget.removeEventListener('mouseup', onMouseUp as any, false)
//     e.currentTarget.removeEventListener('mouseout', onMouseOut as any, false)

// }

// export const onMouseOut = (e: MouseEvent<HTMLDivElement>) => {

// }

// export const onClick = (e: ThreeEvent<MouseEvent>) => {

// }
