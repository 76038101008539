import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router';
import Footer from './components/Footer';
import Header from './components/Header';
import Teaser from './pages/Teaser';
const platformCheck = () => {

  return window.innerWidth < 700 ? 'mobile' : 'desktop'
}

export const PlatformContext = React.createContext<string | null>(null)
export const TouchContext = React.createContext<boolean | null>(null)

function App() {
  const [platform, setPlatform] = useState(platformCheck())
  const [touchScreen, setTouchScreen] = useState(false);
  useEffect(() => {
    window.addEventListener('resize', () => {
      setPlatform(platformCheck())
    })

  }, [])
  window.addEventListener('touchstart', () => {
    setTouchScreen(true)
  })
  return (
    <TouchContext.Provider value={touchScreen}>
      <PlatformContext.Provider value={platform}>
        <div >
          <Header />
          <Teaser />
          <Footer />
        </div >
      </PlatformContext.Provider>
    </TouchContext.Provider>

  );
}

export default App;
