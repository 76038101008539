import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { PlatformContext } from "../App"
import styles from '../styles/header.module.css'
import connectWallet from "../utils/metamask"

const Header = () => {
    const [connect, setConnect] = useState(false);
    const connectWalletPress = async () => {
        setConnect(await connectWallet());
    }
    const platform = useContext(PlatformContext);

    return (
        <>
            <div style={{ zIndex: '3', width: '100vw', maxWidth: '100%', overflow: 'hidden' }}>
                <img src="./intersticelogo.png" className={styles.headerImg} />

            </div>

        </>

    )
}

export default Header

