import { useContext, useEffect, useRef, useState } from "react"
import Carousel3D from "../components/3DCarousel"
import style from '../styles/carousel.module.css'
import { slowReveal } from "../utils/main"
import styles from '../styles/main.module.css';
import Diagram from "../components/Diagram";
import { PlatformContext } from "../App";
import '../index.css'
import RoadMap2 from "../components/Roadmap2";
import About from "../components/About";


const Teaser = () => {
    const platform = useContext(PlatformContext);
    const models = ['/Models/back.glb', '/Models/back.glb', '/Models/back.glb', '/Models/back.glb', '/Models/back.glb', '/Models/back.glb', '/Models/back.glb', '/Models/back.glb', '/Models/back.glb']
    const [currentTime, setTimer] = useState(new Date().getTime())
    const timer = Date.UTC(2022, 2, 18, 21, 0)
    const distance = timer - currentTime
    const touchSreen = useRef(false)
    const days = Math.floor(distance / (1000 * 60 * 60 * 24)).toLocaleString('en-US', { minimumIntegerDigits: 2, })
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toLocaleString('en-US', { minimumIntegerDigits: 2, })
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)).toLocaleString('en-US', { minimumIntegerDigits: 2, })
    const seconds = Math.floor((distance % (1000 * 60)) / 1000).toLocaleString('en-US', { minimumIntegerDigits: 2, })

    useEffect(() => {
        setInterval(() => {
            setTimer(new Date().getTime());
        }, 1000)
    }, [])
    slowReveal()


    return (
        <>

            {/* 
            <div className={style.timer} style={{ color: 'black', width: '100vw', textAlign: 'center', zIndex: '2', position: 'absolute', top: '92.5%', transform: 'translate(0, -92.5%)', maxWidth: '100%', fontFamily: "'Rajdhani', sans-serif" }}>
                {`${days}  :  ${hours}  :  ${minutes}  :  ${seconds}`}
            </div> */}

            <Diagram />

            <div style={{ background: 'linear-gradient( rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%)', top: 'calc(80vh + 185.925px ) ', height: '50vh', width: '100%', position: 'absolute', }} />

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '2', backgroundColor: 'black' }} id="definition">
                <div>
                    <div style={{ textAlign: 'center', display: 'block', fontSize: '26px', verticalAlign: 'middle', color: 'white' }}>
                        <span className={"slowactive"} style={{ fontSize: '40px', fontFamily: 'serif', }}>Interstice <i style={{ fontSize: '22px', }}>noun</i>:</span> An intervening space, especially a very small one.
                    </div>
                    <br />


                    <p style={{ fontSize: '18px', margin: 'auto', color: 'white', width: 'min(800px, 100%)', textAlign: 'center', maxWidth: '100%' }}>
                        The Interstice exists wedged between two parallel dimensions.
                        The physical dimension possesses insurmountable beauty and love but remains bound to the laws of physics whilst the digital realm dissociated from space and time, allows individual freedom and control.
                        <br />
                        <br />
                        The Interstice bridges these two realities through an artistic limbo of the physical artworks created by New Zealand artist Max Brown and the digital realm of NFTs.In this space, artistry and identity can be rediscovered and created anew.
                    </p>
                </div>

            </div>
            <Carousel3D models={models} />
            {/* <About /> */}
            <RoadMap2 />

        </>
    )
}

export default Teaser