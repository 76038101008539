import { useContext, useState } from "react"
import { PlatformContext } from "../App"

const RoadMap2 = () => {
    const platform = useContext(PlatformContext)
    const [hoverState, setHover] = useState(0)
    const width = 125

    return (
        <div style={{ background: 'black', textAlign: 'center', color: 'white', fontSize: '50px', fontFamily: "'Rajdhani', sans-serif", letterSpacing: '4px', }}>
            ROADMAP
            <div style={{ display: "flex", justifyContent: 'center' }}>
                <div className="iconContainer" onMouseOver={() => {
                    setHover(-2)
                }}>
                    <img className="roadmapIcons" src="./Roadmap/outreach.png" ></img>
                </div>
                <div className="iconContainer" onMouseOver={() => {
                    setHover(-1)
                }}>
                    {/* <img src="./Roadmap/immerse.png" className="iconBot roadmapIcons" ></img> */}
                    <img src="./Roadmap/immersegrey.png" className=" roadmapIcons" ></img>

                </div>
                <div className="iconContainer" onMouseOver={() => {
                    setHover(0)
                }}>
                    {/* <img src="./Roadmap/rebirth.png" className="iconBot roadmapIcons" ></img> */}
                    <img src="./Roadmap/rebirthgrey.png" className=" roadmapIcons" ></img>

                </div>
                <div className="iconContainer" onMouseOver={() => {
                    setHover(1)
                }}>
                    {/* <img src="./Roadmap/expansion.png" className="iconBot roadmapIcons" ></img> */}
                    <img src="./Roadmap/expansiongrey.png" className="roadmapIcons" ></img>

                </div>
                <div className="iconContainer" onMouseOver={() => {
                    setHover(2)
                }}>
                    {/* <img src="./Roadmap/synthesise.png" className="iconBot roadmapIcons" ></img> */}
                    <img src="./Roadmap/synthesisegrey.png" className=" roadmapIcons" ></img>

                </div>

            </div>
            <img src="./Roadmap/triangle.png" width="30px" className="triangle" style={{ transform: `translate(${hoverState * width - 15}px, -20px)`, filter: 'invert(1)', position: "absolute" }}></img>
            <div style={{ height: '30vh', backgroundColor: 'black', paddingTop: '40px' }}>
                <div className="roadmapText" style={
                    platform === "desktop" ?
                        { opacity: hoverState === -2 ? "1" : '0', marginLeft: '20%', marginRight: '20%', }
                        :
                        { opacity: hoverState === -2 ? "1" : '0', }
                }>
                    <div style={{ fontSize: '0.8em' }}>
                        Outreach:
                    </div>
                    <div style={{ fontSize: '16px', marginTop: '20px' }}>
                        Project and establish a community that understands and values the core outline of Interstice. A community which comes together through cordial values, like-mindedness and mutual proliferation.
                    </div>
                </div>
                <div className="roadmapText" style={{ fontSize: '0.8em', opacity: hoverState === -1 ? "1" : '0', position: 'absolute', minWidth: '400px', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto' }}>
                    Immerse
                    <br />

                    TBA
                </div>
                <div className="roadmapText" style={{ fontSize: '0.8em', opacity: hoverState === 0 ? "1" : '0', position: 'absolute', minWidth: '400px', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto' }}>
                    Rebirth
                    <br />
                    TBA
                </div>
                <div className="roadmapText" style={{ fontSize: '0.8em', opacity: hoverState === 1 ? "1" : '0', position: 'absolute', minWidth: '400px', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto' }}>
                    Expansion
                    <br />
                    TBA
                </div>
                <div className="roadmapText" style={{ fontSize: '0.8em', opacity: hoverState === 2 ? "1" : '0', position: 'absolute', minWidth: '400px', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto' }}>
                    Synthesis
                    <br />
                    TBA
                </div>

            </div>

        </div>

    )
}

export default RoadMap2