import { Html, useGLTF } from "@react-three/drei";
import { Canvas, extend, useFrame, useThree } from "@react-three/fiber";
import CameraControls from "camera-controls";
import { platform } from "os";
import React, { Suspense, useContext, useEffect, useRef, useState } from "react";
import * as THREE from 'three';
import { Color, Vector3, } from "three";
import { degToRad } from "three/src/math/MathUtils";
import { PlatformContext, TouchContext } from "../App";


CameraControls.install({ THREE: THREE })
extend({ CameraControls })

const Diagram = () => {
    const touch = useContext(TouchContext);
    const platform = useContext(PlatformContext)
    const height = '130vh'
    return (
        <div style={{ display: 'block', height: height }}>
            <Canvas
                gl={{ alpha: true }}
                style={{ height: height, width: '100vw', maxWidth: '100%' }}
                camera={{ position: [0.55, 0, 0], fov: 30 }}
                onCreated={state => state.gl.setClearColor('0x000000', 0)}
            >
                <pointLight position={new Vector3(1, 0, 0)} power={15} />
                <pointLight position={new Vector3(-1, 0, 0)} power={15} />
                {/* <spotLight position={new Vector3(1, -20, 0)} power={20} /> *} */}
                {/* <ambientLight /> */}
                <DiagramCanvas platform={platform!} />
                {touch && <Html fullscreen>
                    <div style={{ display: 'block', width: '100%', height: '100%', zIndex: '2' }}>

                    </div>
                </Html>}

            </Canvas>
        </div>
    )
}

type DiagramCanvasType = {
    platform: string
}

const DiagramCanvas = ({ platform }: DiagramCanvasType) => {
    const { nodes, materials }: any = useGLTF('/Models/back.glb')
    const camControlRef = useRef<CameraControls>()
    const { gl, camera, scene, viewport } = useThree();

    // mirror.materials.back.transparent = true;
    // mirror.materials.back.opacity = 0.5;


    useFrame((_, delta) => {
        camControlRef.current!.update(delta)
        camControlRef.current!.rotate(2 * Math.PI * 0.001, 0, true)
    })
    useEffect(() => {
        camControlRef.current!.mouseButtons.wheel = CameraControls.ACTION.NONE
        camControlRef.current!.mouseButtons.middle = CameraControls.ACTION.NONE
        camControlRef.current!.mouseButtons.right = CameraControls.ACTION.NONE
        camControlRef.current!.mouseButtons.shiftLeft = CameraControls.ACTION.NONE

        camControlRef.current!.minPolarAngle = degToRad(90)
        camControlRef.current!.maxPolarAngle = degToRad(90)
        viewport.aspect = window.innerWidth / window.innerHeight

    }, [])
    useEffect(() => {
        if (platform === 'mobile') {
            console.log('hi')
            // camControlRef.current?.setPosition(0.9, 0, 0)
            camControlRef.current!.touches.one = CameraControls.ACTION.NONE
            camControlRef.current!.touches.two = CameraControls.ACTION.NONE
            camControlRef.current!.touches.three = CameraControls.ACTION.NONE
        }
    }, [platform])

    useFrame((_, delta) => {
        camControlRef.current!.update(delta)
    })


    // const Mirror = () => {
    //     // materials.back.opacity = 0.5;
    //     // materials.back.transparent = true
    //     return (

    //     )

    // }

    // 

    return (
        <>
            {/* @ts-ignore */}
            <cameraControls ref={camControlRef} args={[camera, gl.domElement]} />
            <Suspense fallback={null}>
                <group position={[0, 0.06, 0]} rotation={[0, degToRad(270), degToRad(37.5)]} >

                    <mesh castShadow receiveShadow geometry={nodes.back.geometry} material={materials.back} />
                </group>
                <group position={[0, -0.12, 0]} rotation={[0, degToRad(270 + 180), degToRad(37.5)]}  >
                    <mesh castShadow receiveShadow geometry={nodes.back.geometry} material={materials.back} />
                </group>
                {/* <Mirror /> */}
            </Suspense>

        </>
    )
}

export default Diagram






